import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBookOpen,
  faUserGroup,
  faRankingStar,
  faNewspaper,
  faListOl,
  faCalculator,
  faUsers,
  faRotate,
  faGamepad
} from '@fortawesome/free-solid-svg-icons';
import { faArrowDownLong } from '@fortawesome/free-solid-svg-icons';
import { ClassicLayout } from '../modules/layout/components/classic-layout';
import { GameBackground } from '../modules/main/components/game-background';
import { GameSwitcher } from '../modules/main/components/game-switcher';
import { Box } from '../modules/common/components/box';
import { CommunitySection } from '../modules/common/components/community-section';

import './index.scss';
import { Seo } from '../modules/common/components/seo';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';

const IndexPage: React.FC = () => {
  return (
    <ClassicLayout>
      <GameBackground />
      <Row className="hero-section align-items-center justify-content-center">
        <Col className="text-center" xs={12} lg={12} xl={8}>
          <h1>The best source of guides for your favorite gacha games</h1>
          <h2>
            Prydwen.gg provides news, tier lists and detailed guides for Honkai:
            Star Rail, NIKKE, AFK Journey, Wuthering Waves, Zenless Zone Zero,
            Reverse 1999 and other gacha games. Everything you need in one
            place!
          </h2>
          <p className="choose-game">Choose a game</p>
          <FontAwesomeIcon icon={faArrowDownLong} width="18" />
        </Col>
      </Row>
      <h2 className="sub-title">Popular games</h2>
      <GameSwitcher type="popular" />
      <h2 className="sub-title">All games</h2>
      <GameSwitcher />
      <h2>How Prydwen helps you become a better player</h2>
      <Row className="about-us" id="about-prydwen">
        <Col xs="12" md="6" xl="4">
          <Box isCentered hasIcon hasGradient>
            <FontAwesomeIcon icon={faBookOpen} width="36" />
            <h3>Beginner and advanced guides</h3>
            <p>
              Knowledge is power and Prydwen teaches you what to do (or what not
              to do) in order to gain advantage over other players.
            </p>
          </Box>
        </Col>
        <Col xs="12" md="6" xl="4">
          <Box isCentered hasIcon hasGradient gradientColor="purple">
            <FontAwesomeIcon icon={faUserGroup} width="36" />
            <h3>Character reviews</h3>
            <p>
              Gacha games revolve about collecting characters and Prydwen helps
              you use them in an optimal way.
            </p>
          </Box>
        </Col>
        <Col xs="12" md="6" xl="4">
          <Box isCentered hasIcon hasGradient gradientColor="red">
            <FontAwesomeIcon icon={faRankingStar} width="36" />
            <h3>Tier lists and meta analysis</h3>
            <p>
              Whether you are a meta slave or a collector, playing any gacha
              game without a good tier list makes everything harder.
            </p>
          </Box>
        </Col>
        <Col xs="12" md="6" xl="4">
          <Box isCentered hasIcon hasGradient gradientColor="red">
            <FontAwesomeIcon icon={faNewspaper} width="36" />
            <h3>News and updates</h3>
            <p>
              Stay up to date with any patch notes, new content or other
              important updates.
            </p>
          </Box>
        </Col>
        <Col xs="12" md="6" xl="4">
          <Box isCentered hasIcon hasGradient gradientColor="purple">
            <FontAwesomeIcon icon={faListOl} width="36" />
            <h3>Stats and data</h3>
            <p>
              Prydwen gathers a lot of stats and data about characters and uses
              them to optimize your game play.
            </p>
          </Box>
        </Col>
        <Col xs="12" md="6" xl="4">
          <Box isCentered hasIcon hasGradient>
            <FontAwesomeIcon icon={faCalculator} width="36" />
            <h3>Tools and calculators</h3>
            <p>
              Prydwen develops tools that allow you test and simulate various
              things without wasting resources in game.
            </p>
          </Box>
        </Col>
      </Row>
      <h2>Prydwen in numbers</h2>
      <Row className="about-us" id="about-prydwen">
        <Col xs="12" md="6" xl="3">
          <Box isCentered hasIcon hasGradient>
            <FontAwesomeIcon icon={faUsers} width="36" />
            <h3>1 700 000+</h3>
            <p>Unique visitors each month.</p>
          </Box>
        </Col>
        <Col xs="12" md="6" xl="3">
          <Box isCentered hasIcon hasGradient gradientColor="purple">
            <FontAwesomeIcon icon={faRotate} width="36" />
            <h3>42 000 000+</h3>
            <p>Page views generated every month.</p>
          </Box>
        </Col>
        <Col xs="12" md="6" xl="3">
          <Box isCentered hasIcon hasGradient gradientColor="red">
            <FontAwesomeIcon icon={faDiscord} width="36" />
            <h3>57 000+</h3>
            <p>Discord users who joined our Community.</p>
          </Box>
        </Col>
        <Col xs="12" md="6" xl="3">
          <Box isCentered hasIcon hasGradient>
            <FontAwesomeIcon icon={faGamepad} width="36" />
            <h3>13</h3>
            <p>Gacha games supported.</p>
          </Box>
        </Col>
      </Row>
      <h2>Became a part of the Prydwen community</h2>
      <CommunitySection />
    </ClassicLayout>
  );
};

export default IndexPage;

export const Head: React.FC = () => (
  <Seo description="Prydwen.gg provides news, tier lists and detailed guides for Honkai: Star Rail, NIKKE, AFK Journey, Wuthering Waves, Zenless Zone Zero, Reverse 1999 and other gacha games. Everything you need in one place!" />
);
